import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {connect, ssr_wait} from '@stubhub/react-store-provider';

import CBTModule from '../../../modules/react-cbt-module';
import LoadMore from '../../../modules/react-load-more';
import SearchNoResultsPanel from '../../../modules/react-search-no-results-panel';

import controller from './controller';
import MainPerformerCards from './mainPerformerCards';

export function PerformerTitleComponent({title}) {
  return (
    <div className="PageTitle--TopCategory">
      <h1 className="PageTitle__Text">{title}</h1>
    </div>
  );
}

PerformerTitleComponent.propTypes = {
  title: PropTypes.string,
};

export function PerformerCardsComponent({eventData, hasMore, loading, getMore, title}) {
  return (
    <div className="performer-cards__container">
      <PerformerTitleComponent title={title} />
      <MainPerformerCards eventData={eventData} loading={loading} />
      {hasMore && !loading && <LoadMore onclickHandler={() => getMore()} />}
    </div>
  );
}

PerformerCardsComponent.propTypes = {
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  title: PropTypes.object,
  eventData: PropTypes.array,
  getMore: PropTypes.func.isRequired,
};

class MainContent extends Component {
  constructor(props, context) {
    super(props, context);
    const {categoryId} = this.props;
    if (!props.eventData || !props.eventData.length || props.pid !== categoryId) {
      this.init();
    }
  }

  @ssr_wait
  init() {
    return this.fetchEvents(this.props);
  }

  componentWillUnmount() {
    const {destroy} = this.props;
    destroy();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      categoryId,
      eventStartDate,
      dateRangeKey,
      eventStartDateOverride,
      eventEndDate,
      eventEndDateOverride,
      point,
    } = this.props;
    if (
      nextProps.categoryId !== categoryId ||
      nextProps.eventStartDate !== eventStartDate ||
      nextProps.eventEndDate !== eventEndDate ||
      nextProps.dateRangeKey !== dateRangeKey ||
      nextProps.eventStartDateOverride !== eventStartDateOverride ||
      nextProps.eventEndDateOverride !== eventEndDateOverride ||
      nextProps.point !== point
    ) {
      this.fetchEvents(nextProps, true);
    }
  }

  getMore = () => {
    this.fetchEvents(this.props);
  };

  fetchEvents = (props, reset) => {
    props = {...props};
    const {cookies} = this.context;
    props.cookies = cookies;
    const {fetchEvents} = this.props;

    // Override eventStartDate and eventEndDate, if needed.
    if (props.eventStartDateOverride && props.eventEndDateOverride) {
      props.eventStartDate = new Date(props.eventStartDateOverride);
      props.eventEndDate = new Date(props.eventEndDateOverride);
    }

    return fetchEvents(props, reset);
  };

  render() {
    const {eventData, hasMore, loading, title, isCBTModuleEnabled} = this.props;

    const noEvents = eventData && eventData.length === 0 && loading === false;

    return (
      <>
        <PerformerCardsComponent
          eventData={eventData}
          hasMore={hasMore}
          loading={loading}
          getMore={this.getMore}
          title={title}
        />
        {noEvents &&
          (isCBTModuleEnabled ? (
            <CBTModule entityType="home" className="HomePage__CbtModule" webUri="/" />
          ) : (
            <SearchNoResultsPanel />
          ))}
      </>
    );
  }
}

MainContent.propTypes = {
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  title: PropTypes.object,
  categoryId: PropTypes.string,
  eventStartDateOverride: PropTypes.string, // Externally provided eventStartDate
  eventEndDateOverride: PropTypes.string, // Externally provided eventEndDate
  eventData: PropTypes.array,
  fetchEvents: PropTypes.func.isRequired,
  eventStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dateRangeKey: PropTypes.string,
  eventEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  point: PropTypes.string,
  destroy: PropTypes.func.isRequired,
  pid: PropTypes.string,

  /** If FF for showing cbt module is enabled */
  isCBTModuleEnabled: PropTypes.bool,
};

MainContent.contextTypes = {
  cookies: PropTypes.object,
  globalRegistry: PropTypes.object,
};

export default connect(controller)(MainContent);
